////////////////////////////////////////////////////////////////////////////////////////////////
// GALLERY PREVIEWS ////////////////////////////////////////////////////////////////////////////

// тренажерный зал
import gallery_preview_slide_01 from './images/gallery/previews/slide_01.jpg'
import gallery_preview_slide_02 from './images/gallery/previews/slide_02.jpg'
import gallery_preview_slide_03 from './images/gallery/previews/slide_03.jpg'
import gallery_preview_slide_04 from './images/gallery/previews/slide_04.jpg'
import gallery_preview_slide_05 from './images/gallery/previews/slide_05.jpg'
import gallery_preview_slide_06 from './images/gallery/previews/slide_06.jpg'
import gallery_preview_slide_07 from './images/gallery/previews/slide_07.jpg'

// групповые занятия
import gallery_preview_slide_08 from './images/gallery/previews/slide_08.jpg'
import gallery_preview_slide_09 from './images/gallery/previews/slide_09.jpg'

// спа
import gallery_preview_slide_10 from './images/gallery/previews/slide_10.jpg'
import gallery_preview_slide_11 from './images/gallery/previews/slide_11.jpg'
import gallery_preview_slide_12 from './images/gallery/previews/slide_12.jpg'


////////////////////////////////////////////////////////////////////////////////////////////////
// GALLERY SLIDES //////////////////////////////////////////////////////////////////////////////

// тренажёрный зал
import gallery_slide_01 from './images/gallery/slides/slide_01.jpg'
import gallery_slide_02 from './images/gallery/slides/slide_02.jpg'
import gallery_slide_03 from './images/gallery/slides/slide_03.jpg'
import gallery_slide_04 from './images/gallery/slides/slide_04.jpg'
import gallery_slide_05 from './images/gallery/slides/slide_05.jpg'
import gallery_slide_06 from './images/gallery/slides/slide_06.jpg'
import gallery_slide_07 from './images/gallery/slides/slide_07.jpg'

// групповые занятия
import gallery_slide_08 from './images/gallery/slides/slide_08.jpg'
import gallery_slide_09 from './images/gallery/slides/slide_09.jpg'

// спа
import gallery_slide_10 from './images/gallery/slides/slide_10.jpg'
import gallery_slide_11 from './images/gallery/slides/slide_11.jpg'
import gallery_slide_12 from './images/gallery/slides/slide_12.jpg'


////////////////////////////////////////////////////////////////////////////////////////////////
// EXPORTS /////////////////////////////////////////////////////////////////////////////////////

export const club = {
    name: 'DDX Щука',
    tour_3d: 'https://my.matterport.com/show/?m=bF9kQGjEYLC',
    rating: '4,9',
    rating_number: 4.9,
    reviews: '491 отзыв',
    reviews_link: 'https://yandex.ru/maps/org/ddx_fitness/18925255768/reviews/?ll=37.464572%2C55.809474&z=16',
    address: 'г. Москва, район Щукино, Щукинская ул., 42, ТРК "Щука", 5 этаж',
    route: 'https://yandex.ru/maps/213/moscow/?ll=37.464572%2C55.809474&mode=routes&rtext=~55.809749%2C37.464654&rtt=auto&ruri=~ymapsbm1%3A%2F%2Forg%3Foid%3D18925255768&z=16',
    phone: '+ 7 (495) 255-50-00', // не менять, подмена через uis
    phone_link: 'tel:74952555000', // не менять, подмена через uis
    schedule: 'Ежедневно с 6 до 24',
    coordinates: [55.809748, 37.464654],
    clubs_total: 38,
    ym_code: 95203902,
    vk_code: 3401028,
    clubs_link_with_utms: 'https://ddxfitness.ru/clubs?utm_source=landing_shchukak&utm_medium=cpc&utm_campaign=performance',
}

export const HERO_SLIDES = [
    // '/images/hero/slide_01.webp',
    '/images/hero/slide_02.jpg',
    '/images/hero/slide_03.jpg',
    '/images/hero/slide_04.jpg',
    '/images/hero/slide_05.jpg',
]

export const ZONES_SLIDES = [
    { 
        name: 'Тренажёрный зал', 
        formattedName: 'Тренажёрный зал', 
        title: <div>ТРЕНАЖЁРНЫЙ <br />ЗАЛ</div>, 
        image: '/images/zones/slide_01.jpg',
        preview: '/images/zones/slide_01.jpg',
        list: ['1253 кв. метра топового оборудования', '39 силовых тренажеров', '38 кардио тренажеров', 'Зона свободных весов']
    },
    {
        name: 'Зал групповых занятий',
        formattedName: <span>Зал&nbsp;групповых занятий</span>,
        title: <div>Зал&nbsp;групповых занятий</div>,
        image: '/images/zones/slide_02.jpg',
        preview: '/images/zones/slide_02.jpg',
        list: ['Зал групповых тренировок', 'Cross box', '30 min Fullbody', '15 min ABS', '30 Legs & Hips', 'DDX Athletic', 'Ladies zone']
    },
    { 
        name: 'СПА',
        formattedName: 'СПА',
        title: 'СПА',
        image: '/images/zones/slide_03.jpg',
        preview: '/images/zones/slide_03.jpg',
        list: ['86 кв. метров', 'Сауна и хаммам', 'Соляная комната из гималайской соли', 'Массажные кресла']
    },
]

export const PREVIEW_SLIDES = [
    { id: 'gallery_slide_01', image: gallery_preview_slide_01 },
    { id: 'gallery_slide_02', image: gallery_preview_slide_02 },
    { id: 'gallery_slide_03', image: gallery_preview_slide_03 },
    { id: 'gallery_slide_04', image: gallery_preview_slide_04 },
    { id: 'gallery_slide_05', image: gallery_preview_slide_05 },
    { id: 'gallery_slide_06', image: gallery_preview_slide_06 },
    { id: 'gallery_slide_07', image: gallery_preview_slide_07 },
    { id: 'gallery_slide_08', image: gallery_preview_slide_08 },
    { id: 'gallery_slide_09', image: gallery_preview_slide_09 },
    { id: 'gallery_slide_10', image: gallery_preview_slide_10 },
    { id: 'gallery_slide_11', image: gallery_preview_slide_11 },
    { id: 'gallery_slide_12', image: gallery_preview_slide_12 },
]

export const GALLERY_SLIDES = [
    { id: 'gallery_slide_01', slide: gallery_slide_01, categories: ['тренажёрный зал', 'блочные тренажеры'] },
    { id: 'gallery_slide_02', slide: gallery_slide_02, categories: ['тренажёрный зал', 'блочные тренажеры'] },
    { id: 'gallery_slide_03', slide: gallery_slide_03, categories: ['тренажёрный зал', 'блочные тренажеры'] },
    { id: 'gallery_slide_04', slide: gallery_slide_04, categories: ['тренажёрный зал', 'зона свободных весов'] },
    { id: 'gallery_slide_05', slide: gallery_slide_05, categories: ['тренажёрный зал', 'кардио зона'] },
    { id: 'gallery_slide_06', slide: gallery_slide_06, categories: ['тренажёрный зал', 'кардио зона'] },
    { id: 'gallery_slide_07', slide: gallery_slide_07, categories: ['тренажёрный зал', 'ddx athletics'] },

    { id: 'gallery_slide_08', slide: gallery_slide_08, categories: ['зал групповых занятий'] },
    { id: 'gallery_slide_09', slide: gallery_slide_09, categories: ['зал групповых занятий'] },

    { id: 'gallery_slide_10', slide: gallery_slide_10, categories: ['спа', 'финская сауна'] },
    { id: 'gallery_slide_11', slide: gallery_slide_11, categories: ['спа', 'соляная комната'] },
    { id: 'gallery_slide_12', slide: gallery_slide_12, categories: ['спа', 'хаммам'] },
]

export const CATEGORIES = {
    'тренажёрный зал': ['Все зоны', 'DDX Athletics', 'Зона свободных весов', 'Блочные тренажеры', 'Кардио зона'],
    'спа': ['Все зоны', 'Финская сауна', 'Хаммам', 'Соляная комната'],
    'галерея': ['Все зоны','тренажёрный зал', 'Зал групповых занятий', 'СПА']
}